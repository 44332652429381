<template>
  <div>
    <div class="text-center mt-6" v-if="!dataLoaded">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>

    <div v-show="dataLoaded">
      <v-row class="my-0">
        <v-col cols="12" class="pt-0">
          <v-card class="base-card pa-5" ref="paramsBlock">
            <v-menu offset-y v-model="reportBtn">
              <template #activator="{ on }">
                <v-btn
                  :disabled="disableReportBtn"
                  v-on="on"
                  text
                  color="accent"
                  class="print-btn px-1"
                  title="Сформировать отчет"
                  :loading="loading"
                  :ripple="false"
                >
                  <v-icon left class="file-icon mr-0" size="20">mdi-printer-outline</v-icon>
                  <span class="ml-2">Сформировать отчет</span>
                  <v-icon right class="chevron-icon ml-1">
                    {{ reportBtn ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item dense target="_blank" :to="printReportLink">
                  <v-list-item-title class="list-text">
                    Отчет по кураторам
                  </v-list-item-title>
                </v-list-item>

                <v-list-item dense @click="getTeacherReport">
                  <v-list-item-title class="list-text">
                    Отчет по педагогической нагрузке
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn
              :disabled="disableReportBtn"
              :loading="curatorBtnLoading"
              text
              color="accent"
              class="print-btn px-1 ml-4"
              title="Печать"
              :ripple="false"
              @click="getCuratorReport"
            >
              <v-icon left class="file-icon mr-0" size="20">mdi-file-chart-outline</v-icon>
              <span class="ml-2">Нагрузка кураторов</span>
            </v-btn>

            <div class="inputs">
              <v-autocomplete
                v-model="faculty"
                class="mt-4"
                label="Факультет"
                placeholder="Выберите значение из списка"
                :items="facultiesList"
                item-text="name"
                hide-no-data
                hide-selected
                return-object
                clearable
                @change="resetTable"
              />

              <v-autocomplete
                v-model="department"
                label="Кафедра"
                placeholder="Выберите значение из списка"
                :items="departmentsList"
                item-text="name"
                hide-no-data
                hide-selected
                return-object
                clearable
                @change="resetTable"
              />

              <div class="dates mt-1">
                <div class="date">
                  <DatePicker
                    :date="fromDate"
                    label="Дата c"
                    @setDate="fromDate = $event"
                  />
                </div>

                <div class="date ml-5">
                  <DatePicker
                    :date="toDate"
                    label="Дата по"
                    @setDate="toDate = $event"
                  />
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <ReportTable
        :paramsBlockHeight="paramsBlockHeight"
        :reportList="reportList"
        :tableType="tableType"
      />
    </div>
  </div>
</template>

<script>
import { departmentApi, reportApi, workloadApi } from '@/api'
import download from '@/plugins/download'
import ReportTable from '@/components/management/reports/Table'
import DatePicker from '@/components/ui/DatePicker'

export default {
  name: 'ManagementReports',

  metaInfo: {
    title: 'Отчеты'
  },

  components: {
    ReportTable,
    DatePicker
  },

  async created() {
    await this.getLists()
    this.dataLoaded = true
    this.setParamsBlockHeight()
  },

  mounted() {
    this.hideHtmlOverflow()
    window.addEventListener("resize", this.setParamsBlockHeight)
  },

  destroyed() {
    this.showHtmlOverflow()
    window.removeEventListener("resize", this.setParamsBlockHeight)
  },

  data: () => ({
    reportApi,
    dataLoaded: false,
    loading: false,
    curatorBtnLoading: false,
    reportBtn: false,
    paramsBlockHeight: 0,
    tableType: null,

    department: null,
    faculty: null,
    fromDate: null,
    toDate: null,

    departmentsList: [],
    facultiesList: [],
    reportList: []
  }),

  computed: {
    disableReportBtn() {
      return !((this.faculty || this.department) && this.fromDate && this.toDate)
    },

    printReportLink() {
      const params = {
        departmentId: this.department ? this.department?.id : this.faculty?.id,
        fromDate: this.fromDate || null,
        toDate: this.toDate || null
      }

      return this.department
        ? this.reportApi.getReportCuratorListenerCount(params)
        : this.reportApi.getReportParentDeptListenerCount(params)
    }
  },

  methods: {
    async getLists() {
      try {
        const [departmentsList, facultiesList] = await Promise.all([
          departmentApi.getDepartmentsList(),
          departmentApi.getFacultiesList()
        ])

        this.departmentsList = departmentsList
        this.facultiesList = facultiesList
      } catch (e) {
        console.log(e.status)
      }
    },

    async getCuratorReport() {
      try {
        this.curatorBtnLoading = true

        const body = {
          departmentId: this.department ? this.department.id : this.faculty.id,
          fromDate: this.fromDate,
          toDate: this.toDate
        }

        const data = this.department
          ? await reportApi.getCuratorDepartmentReport(body)
          : await reportApi.getCuratorFacultyReport(body)

        if (data.length) {
          this.reportList = data
          this.tableType = this.department ? 'curatorDepartment' : 'curatorFaculty'
        } else {
          this.$toastr('warning', '', 'Нет данных для формирования отчета')
        }
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка формирования отчета')
        this.resetTable()
      } finally {
        this.curatorBtnLoading = false
      }
    },

    async getTeacherReport() {
      try {
        this.loading = true

        const body = {
          departmentId: this.department ? this.department.id : this.faculty.id,
          fromDate: this.fromDate,
          toDate: this.toDate
        }

        const data = this.department
          ? await workloadApi.getPedByDeptToXls(body)
          : await workloadApi.getPedByFacultyToXls(body)

        data && download(data, 'Отчет по педагогической нагрузке.xlsx')
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка формирования отчета')
      } finally {
        this.loading = false
      }
    },

    resetTable() {
      this.reportList = []
      this.tableType = null
    },

    setParamsBlockHeight() {
      this.$nextTick(() => {
        const paramsBlock = this.$refs.paramsBlock

        if (paramsBlock) {
          this.paramsBlockHeight = paramsBlock.$el.offsetHeight
        }
      })
    },

    hideHtmlOverflow() {
      document.querySelector('html').style.setProperty('overflow-y', 'hidden', 'important')
    },

    showHtmlOverflow() {
      document.querySelector('html').style.overflowY = 'visible'
    }
  }
}
</script>

<style scoped>
.base-card .inputs {
  max-width: 600px;
}

.dates {
  display: flex;
}

.date {
  width: 50%;
}
</style>